module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@5.13.3_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com"],"web":[{"name":"Urbanist","file":"https://fonts.googleapis.com/css2?family=Urbanist:wght@600;700&display=swap"},{"name":"Open+Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
